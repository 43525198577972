// Import the functions you need from the SDKs you need
import { Waiver } from "@tsanghoilun/snow-n-surf-interface/types/checkIn";
import { Session } from "@tsanghoilun/snow-n-surf-interface/types/session";
import { Customer, Staff } from "@tsanghoilun/snow-n-surf-interface/types/user";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { deleteApp, getApps, initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { fromDB, latestBeforeEnds, toDB, toFirebaseTime } from "helpers/date";
import { setAuthUser } from "./slices/authSlice";
import {
  setIsCompleted,
  setIsLoading,
  setMessageModal,
} from "./slices/messageModalSlice";
import { setSession } from "./slices/sessionSlice";
import {
  resetCheckInUserArray,
  setCheckInUser,
  setCheckInUserArray,
  setMissingDataUser,
} from "./slices/usersSlice";
import { store } from "./store";
import { customers, images, sessions, waivers } from "./variables";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Hong_Kong");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_Firebase_apiKey,
  authDomain: process.env.REACT_APP_Firebase_authDomain,
  projectId: process.env.REACT_APP_Firebase_projectId,
  storageBucket: process.env.REACT_APP_Firebase_storageBucket,
  messagingSenderId: process.env.REACT_APP_Firebase_messagingSenderId,
  appId: process.env.REACT_APP_Firebase_appId,
  measurementId: process.env.REACT_APP_Firebase_measurementId,
};

console.log("REACT_APP_ENV", process.env.REACT_APP_ENV);

export const initFirebase = async () => {
  if (getApps().length <= 0) {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    console.log(`New Firebase App initialised`);
    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      // console.log('auth changed');
      if (user) {
        // console.log('user signed in');
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        const db = getFirestore();
        const docRef = doc(db, "staffs", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const unSub = onSnapshot(docRef, async (sp) => {
            const data = sp.data();
            const id = sp.id;
            const authStaff: Staff = {
              id,
              ...data,
            } as Staff;
            store.dispatch(setAuthUser(authStaff));
          });
          GlobalListener();
        } else {
          console.log("no auth user found in DB");
          store.dispatch(setAuthUser(null));
          // store.dispatch(setIsInit(true));
        }
      } else {
        console.log("user signed out");
        store.dispatch(setAuthUser(null));
        // store.dispatch(setIsInit(true));
      }
    });
  } else {
    console.log(`Firebase App already previously initialised`);
  }
};
export const uploadImage = async (file: File, to?: string) => {
  const storage = getStorage();
  const storageRef = ref(
    storage,
    `${to ? to : images}/${new Date().valueOf()}`
  );
  const res = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(res.ref);
  return url;
};

export const loginStaff = async (email: string, password: string) => {
  const auth = getAuth();
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return "success";
  } catch (error) {
    return "error";
  }
};

export const logoutStaff = async () => {
  const auth = getAuth();
  await signOut(auth);
  return "success";
};

const determineMissingData = (customer: Customer) => {
  const data = fromDB(customer);
  if (!customer.isVerified || !customer.realName || !customer.realImage) {
    store.dispatch(setMissingDataUser(data as Customer));
    return "Missing data";
  } else {
    store.dispatch(setCheckInUser(data as Customer));
    return "Ready for check in";
  }
};

export const getUserById = async (id: string) => {
  const db = getFirestore();
  try {
    const res = await getDoc(doc(db, `${customers}/${id}`));
    if (!res.data()) {
      return store.dispatch(setMessageModal("Invalid user"));
    } else {
      const data = res.data();
      determineMissingData({ id, ...data } as Customer);
    }
  } catch (e) {
    store.dispatch(setMessageModal("Invalid user"));
  }
};

export const findUserInSession = (session: Session, user: Customer) => {
  let response;
  let temp: Customer[] = [];
  session.checkedInUsers.find((i: Customer) => {
    if (i.id === user.id) {
      temp.push(i);
    }
  });
  if (temp.length > 0) {
    store.dispatch(setSession(null));
    store.dispatch(setIsCompleted(true));
    store.dispatch(setMessageModal("You were checked in"));
    response = "Duplicated check-in";
  } else {
    const res = checkCheckedInSession(session, user.id);
    if (res === "success") {
      store.dispatch(resetCheckInUserArray());
      determineMissingData(user);
    }
  }

  return response;
};

export const checkCheckedInSession = (session: Session, customerId: string) => {
  let response;
  const sessionEnd = session.end?.valueOf()!;
  const now = new Date().valueOf();
  if (sessionEnd - latestBeforeEnds < now) {
    store.dispatch(setIsCompleted(true));
    return store.dispatch(setMessageModal("Session expired"));
  } else if (!session.checkedInUsers || !session.checkedInUsers.length) {
    store.dispatch(setSession(session));
    response = "success";
    return response;
  } else if (session.checkedInUsers.length >= session.pax) {
    store.dispatch(setSession(null));
    store.dispatch(setIsCompleted(true));
    return store.dispatch(setMessageModal("Max capacity reached"));
  } else {
    session.checkedInUsers.find((i: Customer) => {
      if (i.id === customerId) {
        store.dispatch(setSession(null));
        store.dispatch(setIsCompleted(true));
        store.dispatch(setMessageModal("You were checked in"));
        response = "Duplicated check-in";
        return response;
      } else {
        store.dispatch(setSession(session));
        response = "success";
        return response;
      }
    });
    return response;
  }
};

export const getSessionById = async (id: string, customerId: string) => {
  const db = getFirestore();
  try {
    const res = await getDoc(doc(db, `${sessions}/${id}`));
    if (!res.data()) {
      return store.dispatch(setMessageModal("Session not found"));
    } else {
      const data = res.data();
      const response = checkCheckedInSession(
        { id: res.id, ...data } as Session,
        customerId
      );
      return response;
    }
  } catch (e) {
    store.dispatch(setMessageModal("Invalid session"));
    console.error(e);
  }
};

export const reAuthUser = async (email: string, password: string) => {
  const app = initializeApp(firebaseConfig, "temp");
  const auth = getAuth(app);
  // const credential = EmailAuthProvider.credential(email, password);
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const db = getFirestore();
    const q = query(
      collection(db, customers),
      where("email", "==", res.user.email)
    );
    const unSub = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        return store.dispatch(setMessageModal("Invalid email or password"));
      }
      querySnapshot.forEach((doc) => {
        const data = fromDB(doc.data());
        const id = res.user.uid;
        determineMissingData({ id, ...data } as Customer);
      });
    });
    deleteApp(app);
  } catch (error) {
    store.dispatch(setMessageModal("Invalid email or password"));
    console.error(error);
  }
};

export const getUserByEmail = async (email: string) => {
  const db = getFirestore();
  const q = query(
    collection(db, customers),
    where("email", "==", email)
    // orderBy("isSub", "asc")
  );
  let result: Customer[] = [];
  const sss = await getDocs(q);
  if (sss.empty) {
    return store.dispatch(setMessageModal("User not found"));
  } else {
    sss.forEach((doc) => {
      const data = fromDB(doc.data());
      const id = doc.id;
      result.push({ id, ...data } as Customer);
    });
    if (result.length > 1) {
      store.dispatch(setCheckInUserArray(result));
    } else {
      determineMissingData(result[0]);
    }
  }
};
export const getUserByPhone = async (phone: string) => {
  const db = getFirestore();
  const q = query(
    collection(db, customers),
    where("phone", "==", phone)
    // orderBy("isSub", "asc")
  );
  let result: Customer[] = [];
  const sss = await getDocs(q);
  if (sss.empty) {
    return store.dispatch(setMessageModal("User not found"));
  } else {
    sss.forEach((doc) => {
      const data = fromDB(doc.data());
      const id = doc.id;
      result.push({ id, ...data } as Customer);
    });
    if (result.length > 1) {
      store.dispatch(setCheckInUserArray(result));
    } else {
      determineMissingData(result[0]);
    }
  }
};

export const getUserByMemberId = async (memberId: string) => {
  const db = getFirestore();
  const q = query(collection(db, customers), where("memberId", "==", memberId));
  const unSub = onSnapshot(q, (querySnapshot) => {
    if (querySnapshot.empty) {
      return store.dispatch(setMessageModal("User not found"));
    }
    querySnapshot.forEach((doc) => {
      const id = doc.id;
      const data = fromDB(doc.data());
      determineMissingData({ id, ...data } as Customer);
    });
  });
};

export const updateUserRealInfo = async (
  realName: string,
  file: File | null
) => {
  const imageLink = file ? await uploadImage(file) : null;
  const user = store.getState().users.missingDataUser;
  const db = getFirestore();
  try {
    if (user) {
      const userRef = doc(db, `${customers}/${user.id}`);
      await updateDoc(userRef, {
        realName,
        realImage: imageLink ? imageLink : user.realImage,
      });
      store.dispatch(
        setCheckInUser({
          ...user,
          realName,
          realImage: imageLink ? imageLink : user.realImage,
        })
      );
      store.dispatch(setMissingDataUser(null));
    }
    return "success";
  } catch (error) {
    console.error(error);
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

export const getSessionByNumber = async (sessionNumber: string) => {
  const todayLocale = new Date().toLocaleDateString();
  // const today = toFirebaseTime(new Date(todayLocale));
  // const endOfToday = toFirebaseTime(new Date(todayLocale + ", 23:59:59"));
  const today = Timestamp.fromDate(dayjs.tz(dayjs()).startOf("day").toDate());
  const endOfToday = Timestamp.fromDate(
    dayjs.tz(dayjs()).endOf("day").toDate()
  );
  const checkInUser = store.getState().users.checkInUser;
  const checkInUserArr = store.getState().users.checkInUserArray;

  const db = getFirestore();
  const q = query(
    collection(db, sessions),
    where("start", ">=", today),
    where("start", "<=", endOfToday),
    where("bookingNumber", "==", sessionNumber)
  );
  onSnapshot(q, (querySnapshot) => {
    if (querySnapshot.empty) {
      store.dispatch(setMessageModal("Booking not found"));
    } else {
      querySnapshot.forEach((doc) => {
        const data = fromDB(doc.data());
        if (!!checkInUserArr.length) {
          store.dispatch(setSession({ id: doc.id, ...data } as Session));
        } else {
          checkCheckedInSession(
            { id: doc.id, ...data } as Session,
            checkInUser!.id
          );
        }
      });
    }
  });
};

// return res.data() as IUser;

export const addUserToSession = async (waiverObject: Waiver) => {
  const db = getFirestore();
  const sessionId = store.getState().session.session?.id;
  const checkInUser = toDB(store.getState().users.checkInUser);
  if (checkInUser) {
    const sessionRef = doc(db, `${sessions}/${sessionId}`);
    try {
      await setDoc(
        sessionRef,
        {
          checkedInUsers: arrayUnion(checkInUser),
          waivers: arrayUnion(waiverObject),
        },
        { merge: true }
      );
      return "success";
    } catch (error) {
      console.error(error);
    }
  }
};

export const queueUpCheckIn = async () => {
  // const db = getFirestore();
  // const checkInUser = store.getState().users.checkInUser;
  // if (checkInUser) {
  //   try {
  //     await setDoc(doc(db, `checkInQueue/${checkInUser.id}`), {
  //       ...checkInUser,
  //       dob: toFirebaseTime(checkInUser.dob),
  //       createdAt: toFirebaseTime(checkInUser.createdAt),
  //       checkedIn: false,
  //       time: toFirebaseTime(new Date().valueOf()),
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }
};

export const postWaiverRecord = async (signatureFile: File) => {
  const db = getFirestore();
  const checkInUser = store.getState().users.checkInUser;
  const sessionId = store.getState().session.session?.id!;

  if (checkInUser) {
    try {
      const imageLink = await uploadImage(signatureFile, waivers);
      const waiverObject: Waiver = {
        userId: checkInUser.id,
        userRealName: checkInUser.realName,
        date: new Date(),
        waiver: imageLink,
        sessionId,
      };
      await addDoc(collection(db, "waiverRecords"), {
        ...waiverObject,
        date: toFirebaseTime(waiverObject.date),
      });
      return waiverObject;
    } catch (e) {
      console.error(e);
    }
  }
};

export const GlobalListener = async () => {};
